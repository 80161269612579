import React from 'react'
import './Logo.css'
import BackgroundImage from 'gatsby-background-image'

export default props => (
  <BackgroundImage
    Tag={`div`}
    className={'Logo'}
    fixed={props.logoImageGatsby.childImageSharp.fixed}
  />
)
