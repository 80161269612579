import React from 'react'
import './Footer.css'
import { Heart, Youtube, Instagram } from 'react-feather'

export default ({ footerLinks }) => (
  <div>
    <footer className="footer">
      <div className="container taCenter">
        <ul>
          {footerLinks.map(link => (
            <li key={link.id}>
              <a href={link.fields.slug}>{link.frontmatter.title}</a>
            </li>
          ))}
        </ul>
        <p>
          <span className="social-links">
            <a
              href="https://www.youtube.com/c/SFXCHICALIM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Youtube height="20" color="#d93025" /> SFX Chicalim YouTube{' '}
            </a>
            <a
              href="https://www.instagram.com/sfxchicalim_parish_youth"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram height="20" color="#E1306C" /> SFX Chicalim Youth
            </a>
          </span>
        </p>
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Built with
          <Heart className="heart" fill="#d93025" height="16" />
          by SFX Chicalim Parish Youth
        </span>
      </div>
    </footer>
  </div>
)
