import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

export const query = graphql`
  fragment Meta on MarkdownRemark {
    frontmatter {
      meta {
        title
        description
        noindex
        canonicalLink
      }
    }
  }
`

export default class Meta extends Component {
  render() {
    const {
      title,
      url,
      description,
      absoluteImageUrl = '',
      twitterSiteAccount,
      twitterCreatorAccount,
      noindex,
      canonicalLink,
      siteTitle,
      siteDescription,
      siteUrl
      // overwrite { title, description } if in fields or fields.meta
    } = this.props

    console.log(siteUrl)

    let arrMeta = []

    if (title) {
      arrMeta.push({
        name: `og:title`,
        content: title
      })

      arrMeta.push({
        name: `twitter:title`,
        content: title
      })
    }

    if (description) {
      arrMeta.push({
        name: `description`,
        content: description
      })

      arrMeta.push({
        name: `og:description`,
        content: description
      })

      arrMeta.push({
        name: `twitter:description`,
        content: description
      })
    }

    if (absoluteImageUrl) {
      arrMeta.push({
        name: `og:image`,
        content: `${siteUrl}${absoluteImageUrl}`
      })

      arrMeta.push({
        name: `og:image:alt`,
        content: title
      })

      arrMeta.push({
        name: `twitter:image`,
        content: `${siteUrl}${absoluteImageUrl}`
      })

      arrMeta.push({
        name: `twitter:card`,
        content: `summary_large_image`
      })
    }

    arrMeta.push({
      name: `og:locale`,
      content: `en_US`
    })

    arrMeta.push({
      name: `og:site_name`,
      content: siteTitle
    })

    if (url) {
      arrMeta.push({
        name: `og:type`,
        content: `website`
      })

      arrMeta.push({
        name: `og:url`,
        content: url
      })
    }

    if (noindex) {
      arrMeta.push({
        name: `robots`,
        content: noindex
      })
    }

    if (twitterSiteAccount) {
      arrMeta.push({
        name: `twitter:site`,
        content: twitterSiteAccount
      })
    }

    if (twitterCreatorAccount) {
      arrMeta.push({
        name: `twitter:creator`,
        content: twitterCreatorAccount
      })
    }

    return (
      <Helmet
        defaultTitle={siteTitle}
        titleTemplate={`%s | ${siteTitle}`}
        title={title}
        link={
          canonicalLink
            ? [
                {
                  rel: 'canonical',
                  href: canonicalLink
                }
              ]
            : []
        }
        meta={arrMeta}
      ></Helmet>
    )
  }
}
