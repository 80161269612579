import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import Meta from './Meta'
import Nav from './Nav'
import Footer from './Footer'

import 'modern-normalize/modern-normalize.css'
import './globalStyles.css'

export default ({ children, meta, title, ogImageUrl }) => {
  return (
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          settingsYaml {
            siteTitle
            siteDescription
            siteUrl
            socialMediaCard {
              image
            }
          }
          allPosts: allMarkdownRemark(
            filter: { fields: { contentType: { eq: "postCategories" } } }
            sort: { order: DESC, fields: [frontmatter___date] }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                }
              }
            }
          }
          logoImage: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fixed(width: 60, height: 60, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          footerLinks: allMarkdownRemark(
            filter: {
              frontmatter: {
                title: { nin: ["", "Components Page"] }
                categories: { elemMatch: { category: { eq: null } } }
              }
            }
            sort: { fields: frontmatter___title, order: ASC }
          ) {
            nodes {
              id
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      `}
      render={data => {
        const { siteTitle, socialMediaCard, siteUrl } = data.settingsYaml || {},
          subNav = {
            posts: data.allPosts.hasOwnProperty('edges')
              ? data.allPosts.edges.map(post => {
                  return { ...post.node.fields, ...post.node.frontmatter }
                })
              : false
          }

        return (
          <Fragment>
            <Meta
              absoluteImageUrl={
                ogImageUrl ||
                (socialMediaCard &&
                  socialMediaCard.image &&
                  socialMediaCard.image)
              }
              {...meta}
              {...data.settingsYaml}
              siteTitle={siteTitle}
              siteUrl={siteUrl}
            />

            <Nav subNav={subNav} logoImageGatsby={data.logoImage} />

            <Fragment>{children}</Fragment>

            <Footer footerLinks={data.footerLinks.nodes} />
          </Fragment>
        )
      }}
    />
  )
}
